exports.onRouteUpdate = () => {

	//////////////////////////////////////
	//
	// Toggle Input State
	// - these fields also toggle on form submit in MailchimpForm.js
	//
	//////////////////////////////////////
	const fields = Array.from(document.querySelectorAll(".input-field"));
	fields.forEach((field) => {
		field.onfocus = function () {
			this.classList.remove("has-value");
			this.previousSibling.classList.add("move");
		};
		field.onblur = function () {
			if (this.value.length < 1) {
				this.previousSibling.classList.remove("move");
				this.classList.remove("has-value");
			} else {
				this.classList.add("has-value");
			}
		};
	});
	//

	//////////////////////////////////////
	//
	// Toaster
	//
	//////////////////////////////////////
	if (window.location.href.indexOf("thank-you") > -1) {
		const toaster = document.getElementById("toaster");
		toaster.classList.add("show");
		setTimeout(() => {
			toaster.classList.remove("show");
		}, 4000);
	}
	//
};
